import { isEmpty } from '@helpers/data';
import { AppFCC } from '@types';
import { ErrorsPaneItemsStyled, ErrorsPaneItemStyled, ErrorsPaneStyled } from './styled';
import { ErrorsPaneProps } from './types';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const ErrorsPane: AppFCC<ErrorsPaneProps> = props => {
  const {
    errors,
    bgColor,
    className
  } = props;
  if (!errors || isEmpty(errors)) {
    return null;
  }
  return _jsx(ErrorsPaneStyled, {
    className: className,
    children: _jsx(ErrorsPaneItemsStyled, {
      children: errors.map(({
        message
      }, index) => {
        return _jsx(ErrorsPaneItemStyled //
        , {
          bgColor: bgColor,
          children: message
        }, index);
      })
    })
  });
};