import { useCallback, useEffect } from 'react';
import { FieldValue, FieldValues, useController, useFormContext } from 'react-hook-form';
import { nonUndefinedOr } from '@helpers/data';
import { useFieldWatch } from '@hooks/forms/field-watch';
import { UseFieldControllerOnBlur, UseFieldControllerOnChange, UseFieldControllerProps, UseFieldControllerResult } from './types';
export const useFieldController = <V extends FieldValue<FD>, CV, CE, BE, FD extends FieldValues>(props: UseFieldControllerProps<V, CV, CE, BE, FD>): UseFieldControllerResult<CV, CE, BE, FD> => {
  const {
    name,
    defaultValue,
    value,
    onChange,
    onBlur
  } = props;
  const {
    control,
    register,
    getValues,
    setValue,
    trigger
  } = useFormContext();
  const {
    field: {
      onChange: fieldOnChange,
      onBlur: fieldOnBlur
    },
    fieldState: {
      invalid
    }
  } = useController({
    control,
    name,
    defaultValue: nonUndefinedOr(defaultValue, value)
  });

  /**
   * Controversial solution.
   * We set a default value in the hook below because don't set defaultValues on
   * form level as required in the doc: https://react-hook-form.com/api/useformstate.
   * > Make sure to provide all inputs' defaultValues at the useForm, so hook
   * > form can have a single source of truth to compare whether the form is
   * > dirty.
   */
  useEffect(() => {
    control._defaultValues[name] = defaultValue;
  }, [control, defaultValue, name]);
  useEffect(() => {
    register(name);
  }, [name, register]);
  useEffect(() => {
    if (value === undefined) {
      return;
    }
    const currentValue = getValues(name);
    if (value === currentValue) {
      return;
    }
    setValue(name, (value as FieldValue<FD>));
    trigger(name);
  }, [getValues, name, setValue, trigger, value]);
  const change: UseFieldControllerOnChange<CV, CE> = useCallback((value, event) => {
    fieldOnChange(value);
    onChange?.(value, event);
    if (invalid) {
      trigger(name);
    }
  }, [fieldOnChange, invalid, name, onChange, trigger]);
  const blur: UseFieldControllerOnBlur<BE> = useCallback(event => {
    fieldOnBlur();
    onBlur?.(event);
  }, [fieldOnBlur, onBlur]);
  return {
    watch: useFieldWatch,
    change,
    blur
  };
};