import { ERROR_CODE } from '@config/errors';
import { isEqual } from '@helpers/data';
import { passwordValidationRules } from '@validation/rules/account';
export const accountPasswordChangeValidationRules = {
  oldPassword: passwordValidationRules,
  newPassword: passwordValidationRules,
  newPasswordRepeated: {
    ...passwordValidationRules,
    equality: {
      attribute: 'newPassword',
      message: ERROR_CODE.PASSWORD_REPEATED_NOT_EQUAL_PASSWORD,
      comparator: isEqual
    }
  }
};