export enum ERRORS_PANE_BG_COLOR {
  RED = 'RED',
}
export type ErrorsPaneError = {
  message?: string;
};
export type ErrorsPaneErrors = ErrorsPaneError[];
export type ErrorsPaneProps = {
  className?: string;
  errors: undefined | ErrorsPaneErrors;
  bgColor?: ERRORS_PANE_BG_COLOR;
};
export type ErrorsPaneItemStyledProps = {
  bgColor?: ERRORS_PANE_BG_COLOR;
};