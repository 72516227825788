import { useCallback, useMemo } from 'react';
import { FieldErrors, FieldPath, FieldValues, Message, Resolver, SubmitErrorHandler, SubmitHandler, useForm, UseFormProps } from 'react-hook-form';
import { FORM_ERROR_TYPE } from '@config/forms';
import { isEmpty, isEqual, mapValues, pick } from '@helpers/data';
import { BaseNetError } from '@errors/base-net-error';
import { ValidationError } from '@errors/validation-error';
import { UseFormControllerArgs, UseFormControllerResult, UseFormControllerSubmit } from './types';
export function useFormController<FD extends FieldValues, FP>(args: UseFormControllerArgs<FD, FP>): UseFormControllerResult<FD> {
  const {
    validator,
    validationRules,
    formProps,
    useFormOptions
  } = args;
  const defaultResolver: Resolver<FD> = useCallback(data => {
    if (!validationRules) {
      return {
        values: data,
        errors: {}
      };
    }
    const validationError = validator.validate(data, validationRules);
    if (!validationError) {
      return {
        values: data,
        errors: {}
      };
    }
    const errors = validationError.getFieldErrorsByField();
    if (isEmpty(errors)) {
      return {
        values: data,
        errors: {}
      };
    }
    const parsedErrors = (mapValues(errors,
    //
    fieldError => ({
      type: 'validate',
      message: fieldError.data.message
    })) as FieldErrors<FD>);
    return {
      values: {},
      errors: parsedErrors
    };
  }, [validationRules, validator]);
  const _useFormOptions: UseFormProps<FD> = useMemo(() => {
    return {
      resolver: defaultResolver,
      mode: 'onBlur',
      reValidateMode: 'onBlur',
      ...useFormOptions
    };
  }, [defaultResolver, useFormOptions]);
  const formMethods = useForm<FD>(_useFormOptions);
  const {
    formState,
    register,
    setValue,
    getValues,
    reset,
    watch,
    trigger,
    handleSubmit,
    setError,
    clearErrors
  } = formMethods;
  const {
    errors
  } = formState;
  const submit: UseFormControllerSubmit<FD> = useCallback((formData, event, options) => {
    const _submit: SubmitHandler<FD> = async () => {
      const changedFieldNames = Object.keys(formData);
      const _formData = pick(formData, changedFieldNames);
      const _formPropsFormData = pick(formProps.formData, changedFieldNames);
      try {
        if (!options?.preventFormDataEqualityValidation && isEqual(_formData, _formPropsFormData)) {
          return;
        }
        await formProps.onSubmit(formData, event);
        /**
         * Controversial solution.
         * We reset a form state to a default value that is calculated from
         * defaultValue field props in useFieldController.
         * This is necessary to reset control.defaultValuesRef.current
         * to new defaultValue field prop values to for correct updating
         * of formState.isDirty.
         * > Make sure to provide all inputs' defaultValues at the useForm, so hook
         * > form can have a single source of truth to compare whether the form is
         * > dirty.
         */
        if (formProps.formData) {
          reset();
        }
      } catch (error) {
        if (error instanceof BaseNetError) {
          const serverErrorsByField = error.getFieldErrorsByField();
          for (const fieldName in serverErrorsByField) {
            setError((fieldName as FieldPath<FD>), {
              type: 'validate',
              message: serverErrorsByField[fieldName].message
            });
          }
          const nonValidationRulesFieldErrors = !validationRules ? [] : error.getNonValidationRulesFieldErrors(validationRules);
          const otherErrors = error.getOtherErrors();
          const formErrors = [...nonValidationRulesFieldErrors, ...otherErrors];
          formErrors.forEach((formError, index) => {
            setError((`form-${index}` as any), {
              type: FORM_ERROR_TYPE.FORM,
              message: formError.message
            });
          });
          if (options?.throwErrorOnSubmitError) {
            throw error;
          }
          return;
        }
        throw error;
      }
    };
    const _error: SubmitErrorHandler<FD> = clientErrorsByField => {
      formProps.onSubmitError?.(clientErrorsByField);
      if (options?.throwErrorOnSubmitError) {
        for (const fieldName in clientErrorsByField) {
          setError((fieldName as FieldPath<FD>), {
            type: 'validate',
            message: (clientErrorsByField[fieldName]?.message as undefined | Message)
          });
        }

        /**
         * @todo
         * Should be refactor and throw errors to ValidationError
         * in the future.
         */
        throw new ValidationError('ValidationError', {
          errors: []
        });
      }
    };
    return handleSubmit(_submit, _error)(event);
  }, [handleSubmit, formProps, reset, validationRules, setError]);
  return {
    formMethods,
    isDirty: formState.isDirty,
    register,
    setValue,
    getValues,
    watch,
    trigger,
    reset,
    submit,
    errors,
    clearErrors
  };
}