import { useMemo, useRef } from 'react';
import { FieldError, FormProvider } from 'react-hook-form';
import { FORM_ERROR_TYPE } from '@config/forms';
import { isEqual, omitBy, pickBy } from '@helpers/data';
import { useFormController, UseFormControllerArgs, UseFormControllerErrors, UseFormControllerFormData } from '@hooks/forms/form-controller';
import { useValidator } from '@hooks/validation';
import { FormContainerFieldErrors, FormContainerFormErrors, FormContainerProps } from './types';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const isFieldError = (error: unknown): error is FieldError => {
  if (error &&
  //
  typeof error === 'object' && 'type' in error) {
    return true;
  }
  return false;
};
export const FormContainer = <FD extends UseFormControllerFormData, FP = Record<string, unknown>>(props: FormContainerProps<FD, FP>): React.ReactElement => {
  const {
    Form,
    validationRules,
    formProps,
    useFormOptions
  } = props;
  const validator = useValidator();
  const useFormControllerArgs: UseFormControllerArgs<FD, FP> = useMemo(() => {
    return {
      validator,
      validationRules,
      formProps,
      useFormOptions
    };
  }, [formProps, useFormOptions, validationRules, validator]);
  const {
    formMethods,
    register,
    setValue,
    getValues,
    trigger,
    reset,
    submit,
    errors,
    clearErrors
  } = useFormController(useFormControllerArgs);
  const prevErrors = useRef({
    ...errors
  });

  /**
   * The weird construction below is needed due to useMemo can not detect
   * changes in errors map. Without it errors rendering is broken because Form
   * is memoized with useMemo hook.
   */
  let _errors: UseFormControllerErrors<FD>;
  if (isEqual(errors, prevErrors.current)) {
    _errors = prevErrors.current;
  } else {
    prevErrors.current = {
      ...errors
    };
    _errors = prevErrors.current;
  }
  const form = useMemo(() => {
    const fieldErrors = (omitBy(_errors,
    //
    error => {
      /**
       * Typeguard isFieldError is used here to exclude nested
       * 'react-hook-form' DeepMap values.
       * @todo
       * Reconsider in the future if nested forms functionality
       * is needed.
       */
      return isFieldError(error) && error.type === FORM_ERROR_TYPE.FORM;
    }) as FormContainerFieldErrors<FD>);
    const formErrors = (Object.values(pickBy(_errors,
    //
    error => {
      /**
       * Typeguard isFieldError is used here to exclude nested
       * 'react-hook-form' DeepMap values.
       * @todo
       * Reconsider in the future if nested forms functionality
       * is needed.
       */
      return isFieldError(error) && error.type === FORM_ERROR_TYPE.FORM;
    })) as FormContainerFormErrors);
    return _jsx(Form, {
      ...formProps,
      register,
      setValue,
      getValues,
      trigger,
      reset,
      onSubmit: submit,
      fieldErrors,
      formErrors,
      clearErrors
    });
  }, [_errors, clearErrors, formProps, getValues, register, reset, setValue, submit, trigger]);
  return _jsx(FormProvider, {
    ...formMethods,
    children: form
  });
};