import { ERROR_CODE } from '@config/errors';
import { ValidationRule } from '@validation/validator';
import { SPECIALIST_WALLET_TYPE } from '@types';
import { AccountWithdrawFundsSettingsFormData } from '@forms/account/account-withdraw-funds-settings';
export const emailValidationRules = {
  presence: true,
  email: true,
  format: {
    pattern: `^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$`,
    message: ERROR_CODE.INVALID_EMAIL
  }
};
export const passwordValidationRules = {
  presence: true,
  format: {
    pattern: `^[A-Za-zА-Яа-я\\d\\[\\]\\\\\\/|\\{\\}\\,\\:\\;\\<\\>\\?\\~\\!\\@\\#\\$\\%\\^\\&\\*\\)\\(\\+\\=\\.\\_\\-]{6,}$`,
    message: ERROR_CODE.INVALID_PASSWORD
  },
  length: {
    minimum: 6,
    message: ERROR_CODE.INVALID_PASSWORD_LENGTH
  }
};
export const nameValidationRules = {
  presence: {
    allowEmpty: false
  },
  format: {
    pattern: `^[А-Яа-яA-Za-zЁё№іїєІЇЄґҐ'ʼʻ’]+\\s?[А-Яа-яA-Za-zЁё№іїєІЇЄґҐ'ʼʻ’]+$`,
    message: ERROR_CODE.INVALID_USER_NAME
  },
  length: {
    minimum: 2,
    maximum: 20,
    message: ERROR_CODE.INVALID_USER_NAME_LENGTH
  }
};
export const cardHolderValidationRules = (_value: string, attributes: AccountWithdrawFundsSettingsFormData): ValidationRule => {
  if (!attributes[SPECIALIST_WALLET_TYPE.RUS_BANK_CARD]) {
    return true;
  }
  return {
    presence: {
      allowEmpty: false
    },
    format: {
      pattern: `^[a-zA-Z]+\\s[a-zA-Z]+$`,
      message: ERROR_CODE.INVALID_CARDHOLDER_NAME
    },
    length: {
      minimum: 3,
      maximum: 60,
      message: ERROR_CODE.INVALID_CARDHOLDER_NAME
    }
  };
};
export const cardNumberValidationRules = (value: string): ValidationRule => {
  if (!value) {
    return true;
  }
  return {
    format: {
      pattern: '^(\\d|\\s){13,19}',
      message: ERROR_CODE.INVALID_CREDIT_CARD_NUMBER
    },
    length: {
      minimum: 13,
      maximum: 19,
      message: ERROR_CODE.INVALID_CREDIT_CARD_NUMBER
    }
  };
};
export const webmoneyWalletValidationRules = (value: string): ValidationRule => {
  if (!value) {
    return true;
  }
  return {
    length: {
      is: 13,
      message: ERROR_CODE.INVALID_WEBMONEY_WALLET
    },
    format: {
      pattern: `(R|Z|P)(\\d{12})`,
      message: ERROR_CODE.INVALID_WEBMONEY_WALLET
    }
  };
};
export const qiwiWalletValidationRules = (value: string): ValidationRule => {
  if (!value) {
    return true;
  }
  return {
    format: {
      pattern: `^\\d{9,16}$`,
      message: ERROR_CODE.INVALID_QIWI_WALLET
    }
  };
};
export const confirmationCodeRules = {
  presence: {
    allowEmpty: false
  },
  length: {
    minimum: 3,
    message: ERROR_CODE.INVALID_CONFIRMATION_CODE_LENGTH
  }
};